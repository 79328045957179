import PrivacyComponent from "../components/privacy/privacy";
import React from "react";
import Seo from "../components/seo/seo";

const Privacy = () => {
  return (
    <>
      <Seo title="Datenschutz" />
      <PrivacyComponent />
    </>
  );
};
export default Privacy;
