import {
  privacy,
  privacyContent,
  privacyHeading,
  title,
  section,
  titleInner,
  contactData
} from "./privacy.module.scss";

import React from "react";
import { useContext } from "react";
import { LocaleContext } from "../layout/layout";

const PrivacyComponent = () => {
const { locale } = useContext(LocaleContext);
  return (
    <>
      {locale === "de" ? (
        <div className={privacy}>
          <div className={privacyContent}>
            <h2 className={privacyHeading}>Datenschutz</h2>

            <p className={title}>DATENSCHUTZERKLÄRUNG</p>

            <p className={section}>
              Der Schutz Ihrer persönlichen Daten ist uns ein besonderes
              Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf
              Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In
              diesen Datenschutzinformationen informieren wir Sie über die
              wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
              Website.
            </p>

            <p className={title}>HAFTUNGSBESCHRÄNKUNG</p>

            <p className={section}>
              Verantwortlich für dieses Informationsangebot ist der Herausgeber.
              Die Informationen auf dieser Website wurden mit großer Sorgfalt
              zusammengestellt. Für die Richtigkeit und Vollständigkeit kann
              gleichwohl keine Gewähr übernommen werden. Aus diesem Grund ist
              jegliche Haftung für eventuelle Schäden im Zusammenhang mit der
              Nutzung des Informationsangebots ausgeschlossen. Druch die bloße
              Nutzung dieser Website kommt keinerlei Vertragsverhältnis zwischen
              Nutzer und Anbieter/Herausgeber zustande.
            </p>

            <p className={titleInner}>Hinweis zum Urheberrecht</p>

            <p className={section}>
              Der gesamte Inhalt dieser Website unterliegt dem Urheberrecht.
              Unerlaubte Verwendung, Reproduktion oder Wiedergabe des Inhalts
              oder von Teilen des Inhalts ist untersagt. Wegen einer Erlaubnis
              zur Nutzung des Inhalts wenden Sie sich bitte an den Herausgeber.
            </p>

            <p className={titleInner}>Hinweis zu externen Links</p>

            <p className={section}>
              Soweit von dieser Website auf andere Websites Links gelegt sind,
              wird darauf hingewiesen, dass keinerlei Einfluss auf die
              Gestaltung und die Inhalte der gelinkten Seiten besteht und sich
              deren Inhalt nicht zu Eigen gemacht wird. Dies gilt für alle auf
              dieser Seite ausgebrachten externen Links und für alle Inhalte der
              Seiten, zu denen Werbemittel (z.B. Banner, Textanzeigen,
              Videoanzeigen) führen. Für verlinkte Seiten gilt, dass
              rechtswidrige Inhalte zum Zeitpunkt der Verlinkung nicht erkennbar
              waren. Die Links werden regelmäßig auf rechtswidrige Inhalte
              überprüft und bei Rechtsverletzungen unverzüglich entfernt.
            </p>

            <p className={title}>COOKIES</p>

            <p className={section}>
              Damit dieses Internetportal ordnungsgemäß funktioniert, legen wir
              manchmal kleine Dateien – sogenannte Cookies – auf Ihrem Gerät ab.
              Das ist bei den meisten Websites üblich.
            </p>

            <p className={titleInner}>Was sind Cookies?</p>

            <p className={section}>
              Ein Cookie ist eine kleine Textdatei, die ein Webportal auf Ihrem
              Rechner, Tablet-Computer oder Smartphone hinterlässt, wenn Sie es
              besuchen. So kann sich das Portal bestimmte Eingaben und
              Einstellungen (z. B. Login, Sprache, Schriftgröße und andere
              Anzeigepräferenzen) über einen bestimmten Zeitraum „merken“, und
              Sie brauchen diese nicht bei jedem weiteren Besuch und beim
              Navigieren im Portal erneut vorzunehmen.
            </p>

            <p className={titleInner}>Wie setzen wir Cookies ein?</p>

            <p className={section}>
              Auf unserer Seite verwenden wir Cookies zur Speicherung Ihrer
              Vorlieben bei der Bildschirmanzeige, z. B. Kontrast und
              Schriftgröße Ihrer Entscheidung zur (oder gegen die) Nutzung von
              Cookies auf diesem Portal. Weiters werden Cookies von Google
              Analytics zur Analyse der Seitenaufrufe sowie Cookies von Spotify
              für das Anzeigen und Abspielen von Playlists gesetzt.
            </p>

            <p className={section}>
              Das Akzeptieren von Cookies ist zwar für die Nutzung des Portals
              nicht unbedingt erforderlich, macht das Surfen aber angenehmer.
              Sie können Cookies blockieren oder löschen – das kann jedoch
              einige Funktionen dieses Portals beeinträchtigen.
            </p>

            <p className={section}>
              Die mithilfe von Cookies erhobenen Informationen werden nicht dazu
              genutzt, Sie zu identifizieren, und die Daten unterliegen
              vollständig unserer Kontrolle. Die Cookies dienen keinen anderen
              Zwecken als den hier genannten.
            </p>

            <p className={titleInner}>Welche Cookies werden gesetzt?</p>

            <p className={section}>
              Auf unserer Seite verwenden wir Cookies zur Speicherung Ihrer
              Vorlieben bei der Bildschirmanzeige, z. B. Kontrast und
              Schriftgröße Ihrer Entscheidung zur (oder gegen die) Nutzung von
              Cookies auf diesem Portal. Weiters werden Cookies von Google
              Analytics zur Analyse der Seitenaufrufe sowie Cookies von Spotify
              für das Anzeigen und Abspielen von Playlists gesetzt.
            </p>

            <p className={titleInner}>Kontrolle über Cookies</p>

            <p className={section}>
              Sie können Cookies nach Belieben steuern und/oder löschen. Wie,
              erfahren Sie hier: aboutcookies.org. Sie können alle auf Ihrem
              Rechner abgelegten Cookies löschen und die meisten Browser so
              einstellen, dass die Ablage von Cookies verhindert wird. Dann
              müssen Sie aber möglicherweise einige Einstellungen bei jedem
              Besuch einer Seite manuell vornehmen und die Beeinträchtigung
              mancher Funktionen in Kauf nehmen.
            </p>

            <p className={title}>IHRE RECHTE</p>

            <p className={section}>
              Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
              Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und
              Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer
              Daten gegen das Datenschutzrecht verstößt oder Ihre
              datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt
              worden sind, können Sie sich bei der Aufsichtsbehörde beschweren.
              In Österreich ist dies die Datenschutzbehörde.
            </p>

            <p className={contactData}>
              Sie erreichen uns unter folgenden Kontaktdaten:
            </p>

            <p className={section}>Powerfusion GmbH & Co KG</p>
            <p>Triesterstraße 10/424</p>
            <p>2351 Wiener Neudorf</p>

            <p>E-Mail: office@powerfusion.at</p>

            <p className={section}>
              Geschäftsführer: Herr Mag. D'Alonzo Vittorio
            </p>
            <p>
              Unternehmensgegenstand: Entwicklung und Errichtung
              Energieversorgungssystemen
            </p>

            <p className={section}>Firmenbuchnummer: FN 526019 h</p>
            <p>Firmenbuchgericht: Landesgericht Wiener Neustadt</p>
            <p>UID-Nummer: ATU75087601</p>
            <p>Aufsichtsbehörde: Bezirkshauptmannschaft Mödling</p>

            <p className={title}>SONSTIGES</p>

            <p className={section}>
              Soweit auf dieser Website personenbezogene Daten (Name, Anschrift,
              Email-Adresse etc.) erhoben werden, geschieht dies im freiwilligen
              Einvernehmen mit dem Nutzer und in dessen Kenntnis.
            </p>

            <p className={section}>
              Es wird darauf hingewiesen, dass hinsichtlich der Datenübertragung
              über das Internet (z.B. bei der Kommunikation per E-Mail) keine
              sichere Übertragung gewährleistet ist. Empfindliche Daten sollten
              daher entweder gar nicht oder nur über eine sichere Verbindung
              (SSL) übertragen werden.
            </p>
          </div>
        </div>
      ) : (
        <div className={privacy}>
          <div className={privacyContent}>
            <h2 className={privacyHeading}>Data protection</h2>

            <p className={title}>DATA PROTECTION DECLARATION</p>

            <p className={section}>
              The protection of your personal data is of particular concern to
              us. We therefore process your data exclusively on the basis of the
              legal provisions (DSGVO, TKG 2003). In this data protection
              information, we inform you about the most important aspects of
              data processing within the framework of our website.
            </p>

            <p className={title}>LIMITATION OF LIABILITY</p>

            <p className={section}>
              The publisher is responsible for this information offer. The
              information on this website has been compiled with great care.
              Nevertheless, no guarantee can be given for its correctness and
              completeness. For this reason, any liability for possible damages
              in connection with the use of the information offered is excluded.
              The mere use of this website does not constitute any contractual
              relationship between the user and the provider/publisher.
            </p>

            <p className={titleInner}>Copyright notice</p>

            <p className={section}>
              The entire content of this website is subject to copyright.
              Unauthorised use, reproduction or representation of the content or
              parts of the content is prohibited. For permission to use the
              content, please contact the publisher.
            </p>

            <p className={titleInner}>Note on external links</p>

            <p className={section}>
              As far as there are links from this website to other websites, it
              is pointed out that there is no influence whatsoever on the design
              and content of the linked pages and that their content is not
              adopted as our own. This applies to all external links on this
              site and to all content of the sites to which advertising material
              (e.g. banners, text ads, video ads) leads. For linked pages, it
              applies that illegal contents were not recognisable at the time of
              linking. The links are regularly checked for illegal content and
              removed immediately in the event of infringements.
            </p>

            <p className={title}>COOKIES</p>

            <p className={section}>
              In order for this internet portal to function properly, we
              sometimes place small files - so-called cookies - on your device.
              This is common practice on most websites.
            </p>

            <p className={titleInner}>What are cookies?</p>

            <p className={section}>
              A cookie is a small text file that a web portal leaves on your
              computer, tablet computer or smartphone when you visit it. This
              allows the portal to "remember" certain entries and settings (e.g.
              login, language, font size and other display preferences) over a
              certain period of time, and you do not need to enter them again
              each time you visit and navigate the portal.
            </p>

            <p className={titleInner}>How do we use cookies?</p>

            <p className={section}>
              On our site, we use cookies to store your screen display
              preferences, e.g. contrast and font size of your decision to (or
              not to) use cookies on this portal. Cookies are also set by Google
              Analytics to analyse page views and by Spotify to display and play
              playlists.
            </p>

            <p className={section}>
              Accepting cookies is not absolutely necessary for using the
              portal, but it does make surfing more pleasant. You can block or
              delete cookies, but this may affect some of the features of this
              portal.
            </p>

            <p className={section}>
              The information collected using cookies is not used to identify
              you and the data is entirely under our control. The cookies are
              not used for any other purposes than those mentioned here.
            </p>

            <p className={titleInner}>What cookies are set?</p>

            <p className={section}>
              You can control and/or delete cookies as you wish. You can find
              out how here: aboutcookies.org. You can delete all cookies stored
              on your computer and set most browsers to prevent cookies from
              being stored. However, you may then have to make some settings
              manually each time you visit a page and accept that some functions
              may be impaired.
            </p>

            <p className={titleInner}>Control over cookies</p>

            <p className={section}>
              You can control and/or delete cookies as you wish. You can find
              out how here: aboutcookies.org. You can delete all cookies stored
              on your computer and set most browsers to prevent cookies from
              being stored. However, you may then have to make some settings
              manually each time you visit a page and accept that some functions
              may be impaired.
            </p>

            <p className={title}>YOUR RIGHTS</p>

            <p className={section}>
              You are generally entitled to the rights of information,
              correction, deletion, restriction, data portability, revocation
              and objection. If you believe that the processing of your data
              violates data protection law or that your data protection rights
              have been violated in any other way, you can complain to the
              supervisory authority. In Austria, this is the data protection
              authority.
            </p>

            <p className={contactData}>
              You can reach us at the following contact details:
            </p>

            <p className={section}>Powerfusion GmbH & Co KG</p>
            <p>Triesterstraße 10/4/424</p>
            <p>2351 Wiener Neudorf</p>

            <p>E-mail: office@powerfusion.at</p>

            <p className={section}>CEO: Mag. D'Alonzo Vittorio</p>
            <p>
              Object of the company: Development and construction of
              sustainable, individually customised energy supply systems and
              distribution of products for the construction and control of these
              energy supply systems.
            </p>

            <p className={section}>Company register number: FN 526019 h</p>
            <p>Commercial register court: Regional court Wiener Neustadt</p>
            <p>UID number: ATU75087601</p>
            <p>Supervisory Authority: Bezirkshauptmannschaft Mödling</p>

            <p className={title}>OTHER</p>

            <p className={section}>
              As far as personal data (name, address, email address etc.) are
              collected on this website, this is done in voluntary agreement
              with the user and in his knowledge. It is pointed out that with
              regard to data transmission via the Internet (e.g. communication
              by e-mail), no secure transmission can be guaranteed. Sensitive
              data should therefore either not be transmitted at all or only via
              a secure connection (SSL).
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyComponent;
