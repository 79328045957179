// extracted by mini-css-extract-plugin
export var contactData = "privacy-module--contactData--c5c86";
export var dBlock = "privacy-module--d-block--bc318";
export var dFlex = "privacy-module--d-flex--66207";
export var dInline = "privacy-module--d-inline--4aa32";
export var dLgBlock = "privacy-module--d-lg-block--6e012";
export var dLgFlex = "privacy-module--d-lg-flex--1b433";
export var dLgInline = "privacy-module--d-lg-inline--bf518";
export var dLgNone = "privacy-module--d-lg-none--cbe75";
export var dMdBlock = "privacy-module--d-md-block--5caed";
export var dMdFlex = "privacy-module--d-md-flex--2a68b";
export var dMdNone = "privacy-module--d-md-none--69586";
export var dNone = "privacy-module--d-none--b5938";
export var privacy = "privacy-module--privacy--2eaff";
export var privacyContent = "privacy-module--privacyContent--3e12a";
export var privacyHeading = "privacy-module--privacyHeading--22a16";
export var section = "privacy-module--section--17b28";
export var sectionBig = "privacy-module--sectionBig--766cb";
export var title = "privacy-module--title--772be";
export var titleInner = "privacy-module--titleInner--304fa";